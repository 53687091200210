<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="component_type == 'edit'"
              >Edit User Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="component_type == 'add'">Add User</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="component_type == 'edit'">Edit User Information</span
            ><span v-if="component_type == 'add'">Add User</span>
          </h3>

          <p class="py-4">
            Please fill out the form below to add a user. 
            <br><br>
            <v-alert type="error">After creating a user,
            you will need to associate their account to a company so they can
            log in and view opportunities. If you fail this step, they will have issues after log in. <br><br>HAS THE COMPANY BEEN CREATED FIRST?</v-alert>
          </p>
          <v-form @submit="submitCreateUser" ref="form" v-model="form.valid">
            <v-text-field
              v-model="userData.first_name"
              label="First Name"
              :rules="form.rules.required"
              outlined
            >
            </v-text-field>
            <v-text-field
              v-model="userData.last_name"
              label="Last Name"
              :rules="form.rules.required"
              outlined
            >
            </v-text-field>
            <v-text-field
              v-model="userData.email"
              label="Email"
              :rules="form.rules.required"
              outlined
            >
            </v-text-field>
            <v-textarea
              v-model="userData.description"
              label="Description"
              outlined
            ></v-textarea>

            <v-text-field
              label="Phone Number"
              placeholder="Phone Number"
              outlined
              v-model="userData.phone"
              :rules="form.rules.phoneRules"
            ></v-text-field>
            <v-switch
              v-model="userData.send_summary_offer_email"
              label="Send this user offer emails"
            ></v-switch>

            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="component_type == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateUser"
                  >Create User
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  >
                  </v-progress-circular>
                </v-btn>
                <v-btn
                  v-if="component_type == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateUser"
                  >Update User
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  >
                  </v-progress-circular>
                </v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'edit'"
                  @click="
                    $router.go(-1)
                  "
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  class="ml-4"
                  v-if="component_type == 'add'"
                  @click="$router.push({ path: `/dashboard/admin/user/list` })"
                  >Go To All Users List</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "user_add",
  props: ["dealId", "user_id"],
  data() {
    return {
      component_type: "",
      dealActiveOptions: ["active", "deactivated", "awarded", "completed"],
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",
      form: {
        valid: false,
        rules: {
          required: [(v) => !!v || "Name is required"],
          companyPhoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              "Phone must be numbers only (ex. 7803334444)",

            (v) =>
              (v && v.length == 10) ||
              "Phone number must be 10 digits no spaces or characters (format 7803334444)",
          ],
          phoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              "Phone must be numbers only (ex. 7803334444)",
            (v) => !!v || "Phone number is required",
            (v) =>
              (v && v.length == 10) ||
              "Phone number must be 10 digits no spaces or characters (format 7803334444)",
          ],
          emailRules: [
            (v) => (v && !!v) || "E-mail is required",
            (v) => (v && /.+@.+/.test(v)) || "E-mail must be valid",
          ],
        },
      },
      userData: {
        email: "",
        password: "",
        phone: "",
        first_name: "",
        last_name: "",
        send_summary_offer_email: true,
      },
    };
  },
  methods: {
    submitCreateUser() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.form.valid) {
        API()
          .post("/api/internal-admin/admin_create_user", this.userData)
          .then((res) => {
            console.log(res.data);
            this.formLoading = false;
            // console.log(res);
            this.$router.push(`/dashboard/admin/user/read/${res.data.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    submitUpdateUser() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.form.valid) {
        API()
          .patch(`/api/internal-admin/admin_update_user_information`, this.userData)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(`/dashboard/admin/user/read/${this.userData.id}`);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
    async get_user_info(){
      try {
        
        let user_info = await API().get(`/api/internal-admin/get_user_info_view/${this.user_id}`);

        this.userData = user_info.data

      } catch (error) {
        console.log(error)
      }
    }
  },
  async mounted() {


    if (this.$route.name == "admin_add_user") {
      this.component_type = "add";
      this.componentLoading = false;
    } 

    if (this.$route.name == "admin_edit_user") {
      this.component_type = "edit";
      await this.get_user_info();
      this.componentLoading = false;
    } 
    
    
  },
};
</script>

<style>
</style>